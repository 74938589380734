import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import MiniContent from '../components/mini-content.js'
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";

function IndexPage({ data: { fileName: { childImageSharp: { gatsbyImageData: image } } } }) {

  return (
    <Layout>
      <SEO
        keywords={[`jugendhilfe`, `elternarbeit`, `kinderschutz`, `jugendhilfe`, `kinderhilfe`, `familienhilfe`]}
        title="Startseite"
      />

      <div className="transform -rotate-2 -mt-8 mb-16"> 
        <h1>quergedacht ist ein junger freier Träger der Kinder- und Jugendhilfe mit Sitz in Krefeld</h1>
      </div>
      

      <div className='flex flex-wrap -mx-2'>
        <MiniContent itemsPerRow={3} title='Jugendhilfe' content='Als freier Träger der Kinder- & Jugendhilfe sind wir im Auftrag der Jugendämter tätig' />
        <MiniContent itemsPerRow={3} title='Elternarbeit' content='Mit einem systemischen Blick auf das Familiensystem fördern wir Veränderung' />
        <MiniContent itemsPerRow={3} title='Kinderschutz' content='Das Kindeswohl steht für uns zu jedem Zeitpunkt im Mittelpunkt' />
      </div>

      { image ? <GatsbyImage image={{ ...image, aspectRatio: 3 }} className='mt-8 mp-4' /> : null }

      
    </Layout>
  );
}

IndexPage.propTypes = {
  data: PropTypes.object,
  image: PropTypes.object
}

export default IndexPage

export const pageQuery = graphql`{
  fileName: file(relativePath: {eq: "index.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`